<script setup>
defineProps({ TEAM: { type: String, required: true } });
const emit = defineEmits(["on-close", "on-request-logout"]);

function requestLogout() {
  emit("on-request-logout");
}

function close() {
  emit("on-close");
}
</script>
<template>
  <div class="card has-background-grey-darker">
    <header class="card-header">
      <div class="card-header-title">
        <h4 class="title is-3 has-text-white">Vom Spiel abmelden?</h4>
      </div>
    </header>
    <div class="card-content has-text-white">
      <div class="block">
        <!-- <h4 class="title is-4 has-text-white">
          Willst du dieses Gerät vom Spiel abmelden?
        </h4> -->
        <p class="subtitle is-6 has-text-white">
          Wenn du mit diesem Gerät das Spiel verlassen hast, wirst du auf zum
          Startbildschirm umgeleitet. Dort kannst du das Spiel mit der Spiel-ID:
          <strong class="has-text-white">{{ TEAM }}</strong>
          auch wieder fortsetzten.
        </p>
        <div class="field is-grouped">
          <p class="control">
            <button class="button is-warning" @click="close()">
              Spiel fortsetzen
            </button>
          </p>
          <p class="control">
            <button class="button is-warning is-light" @click="requestLogout()">
              Spiel verlassen
            </button>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
