export default [
    {
        file: require("@/assets/audio/UI-Sounds/Slide_In_v2.mp3"),
        name: "SLIDE_IN",
    },
    {
        file: require("@/assets/audio/UI-Sounds/Slide_Out_v2.mp3"),
        name: "SLIDE_OUT",
    },
    {
        file: require("@/assets/audio/UI-Sounds/Klick_mechanic_1.mp3"),
        name: "BUTTON_LCD",
    },
    {
        file: require("@/assets/audio/UI-Sounds/Klick_small_1.mp3"),
        name: "DIAL",
    },
    {
        file: require("@/assets/audio/UI-Sounds/Search.mp3"),
        name: "SEARCH",
    },
    {
        file: require("@/assets/audio/UI-Sounds/fehler_keine_zeitdatei.mp3"),
        name: "NOTFOUND",
    },
    {
        file: require("@/assets/audio/UI-Sounds/fehler_paragaph_29b.mp3"),
        name: "DATENSCHUTZ",
    },
    {
        file: require("@/assets/audio/UI-Sounds/SignalSoft.mp3"),
        name: "NEWTEXT",
    },
    {
        file: require("@/assets/audio/Ortswechsel/Ortswechsel_1.mp3"),
        name: "Ortswechsel_1",
    },
    {
        file: require("@/assets/audio/Ortswechsel/Ortswechsel_2.mp3"),
        name: "Ortswechsel_2",
    },
    {
        file: require("@/assets/audio/Ortswechsel/Ortswechsel_3.mp3"),
        name: "Ortswechsel_3",
    },
    {
        file: require("@/assets/audio/Ortswechsel/Ortswechsel_4.mp3"),
        name: "Ortswechsel_4",
    },
    {
        file: require("@/assets/audio/Ortswechsel/Ortswechsel_5.mp3"),
        name: "Ortswechsel_5",
    },
    {
        file: require("@/assets/audio/Ortswechsel/Ortswechsel_6.mp3"),
        name: "Ortswechsel_6",
    },
    {
        file: require("@/assets/audio/Ortswechsel/Ortswechsel_7.mp3"),
        name: "Ortswechsel_7",
    },
];
