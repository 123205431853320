<script setup></script>
<template>
  <div class="card has-background-grey-darker">
    <div class="card-content has-text-white">
      <div class="block">
        <h4 class="title is-4 has-text-white">Wie komme ich zum Spiel zurück?</h4>
        <p class="subtitle is-6 has-text-white">
          Drück da oben auf den Button auf dem
          <i>Zurück</i>
          steht.
        </p>
      </div>
      <div class="block">
        <h4 class="title is-4 has-text-white">
          Wer hat denn den Mist hier programmiert?
        </h4>
        <p class="subtitle is-6 has-text-white">Das war dann wohl ich.</p>
      </div>
    </div>
  </div>
</template>
