<script setup>
import { ref } from "vue";
const props = defineProps({
  team: { type: Object, required: true },
  isLoading: { type: Boolean, required: true },
  hasError: { type: String, required: true },
});

const emit = defineEmits(["on-go-back", "on-join"]);

const code = ref(props.team?.id || "");

function goBack() {
  emit("on-go-back");
}
function joinTeam() {
  emit("on-join", code.value);
}
</script>
<template>
  <div class="card has-background-grey-darker">
    <header class="card-header">
      <div class="card-header-title">
        <h4 class="title is-3 has-text-white">Gib hier deinen Game ID ein</h4>
      </div>
    </header>
    <div class="card-content has-text-white">
      <div class="content">
        <div class="field">
          <label class="label has-text-white">Game ID</label>
          <div class="control">
            <input
              class="input"
              type="text"
              placeholder="5-stelliger Code"
              :value="code"
              @input="
                (evt) => {
                  code = evt.target.value;
                }
              "
              :disabled="isLoading"
              @keyup.enter="joinTeam"
            />
          </div>
          <p v-if="hasError" class="help has-text-danger">
            {{ hasError }}
          </p>
        </div>
      </div>
    </div>
    <footer class="card-footer">
      <div class="card-footer-item">
        <button
          class="button is-warning is-light"
          @click="goBack()"
          :disabled="isLoading"
        >
          Zurück
        </button>
      </div>
      <div class="card-footer-item">
        <button
          class="button is-warning"
          :class="{ 'is-loading': isLoading }"
          @click="joinTeam"
          :disabled="!code"
        >
          Weiter spielen
        </button>
      </div>
    </footer>
  </div>
</template>
