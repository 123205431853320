<script setup>
import trashSVG from "@/assets/svg/trash.svg";

defineProps({
  team: { type: Object, required: true },
  PLAYER: { type: Array, required: true },
  isLoading: { type: Boolean, required: true },
});
const emit = defineEmits(["on-next", "on-delete-player", "start-game"]);

function deletePlayer(player) {
  emit("on-delete-player", player);
}

function startGame() {
  emit("start-game");
}

// function goNext() {
//   emit("on-next");
// }
</script>
<template>
  <div class="card has-background-grey-darker">
    <header class="card-header">
      <div class="card-header-title">
        <div>
          <h3 class="title is-3 has-text-white">Anmelden</h3>
        </div>
      </div>
    </header>
    <div class="card-content has-text-white">
      <div class="block">
        <p class="pb-2">
          Gebt den folgenden Code auf den Mobilgeräten ein um dem Spiel beizutreten:
        </p>
        <h3 class="title is-5 has-text-white">{{ team.id }}</h3>
      </div>
      <!-- <div class="block">
        <span>Bitte öffne die folgende URL auf deinem Smartphone:</span>
        <h3 class="title is-3 has-text-white">satellit.machinaex.org</h3>
      </div> -->
      <!-- <div class="block">
        <p>Und gib den Code ein:</p>
        <h3 class="title is-3 has-text-white">{{ team.id }}</h3>
      </div> -->
      <div class="block">
        <h5 class="title is-5 has-text-white mb-3">Verbundene Spieler:innen</h5>
        <div v-if="PLAYER.length > 0" class="player-list">
          <TransitionGroup name="list" tag="ul">
            <li v-for="p in PLAYER" :key="team.id + 'pl' + p.name">
              <span class="is-impact-label is-size-5 mr-3">
                {{ p.name }}
              </span>
              <span
                class="icon has-background-danger is-pulled-right is-clickable"
                @click="deletePlayer(p.name)"
              >
                <img :src="trashSVG" alt="Löschen" />
              </span>
            </li>
          </TransitionGroup>
        </div>
      </div>
    </div>
    <footer class="card-footer">
      <div class="card-footer-item">
        <button
          class="button is-warning"
          :class="{ 'is-loading': isLoading }"
          :disabled="isLoading"
          @click="startGame"
        >
          Los gehts
        </button>
      </div>
    </footer>
  </div>
</template>
