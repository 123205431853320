<script setup>
import { ref, computed } from "vue";
import arrowDownCircleSVG from "@/assets/svg/arrow-right-circle-line.svg";
import chevronUpSVG from "@/assets/svg/arrow-up-s-line.svg";
import chevronRightSVG from "@/assets/svg/arrow-right-s-line.svg";

const props = defineProps({
  location: { type: Object, required: true },
  locations: { type: Array, required: true },
});
const emit = defineEmits(["close-modal", "location-select"]);
const selectionA = ref("1");
const selectionB = ref("A");
const maxLocationsShowen = 6;
const showAll = ref(false);
const lastLocations = computed(() => {
  let locations = props.locations.slice();
  const currIndex = locations.findIndex((l) => l._id === props.location._id);
  if (currIndex >= 0) locations.splice(currIndex, 1);
  return showAll.value ? locations : locations.slice(0, maxLocationsShowen);
});

const optionsA = ref([
  { text: "1", value: "1" },
  { text: "2", value: "2" },
  { text: "3", value: "3" },
  { text: "4", value: "4" },
  { text: "5", value: "5" },
]);

const optionsB = ref([
  { text: "A", value: "A" },
  { text: "B", value: "B" },
  { text: "C", value: "C" },
  { text: "D", value: "D" },
  { text: "E", value: "E" },
]);

function locationSelect(coords) {
  emit("location-select", coords);
}
</script>

<template>
  <div class="modal is-active">
    <div class="modal-background"></div>
    <div class="modal-content">
      <div class="card location-picker">
        <div class="card-content">
          <section class="block">
            <h5 class="title is-size-5 mb-3">Ihr seid hier:</h5>
            <button class="button location-button" disabled>
              <span>{{ location?.title || "निर्वाण" }}</span>
              <small class="is-size-7 pl-1 has-text-weight-normal">
                ({{ location.coordinates }})
              </small>
            </button>
          </section>
          <section class="block">
            <h5 class="title is-size-5 mb-3">Zuletzt besuchte Orte</h5>
            <div class="buttons mb-1">
              <!-- <TransitionGroup name="list" tag="div" class="buttons"> -->
              <button
                v-for="currLocation in lastLocations"
                :key="currLocation._id"
                class="button location-button"
                @click="locationSelect(currLocation.coordinates)"
              >
                <span>{{ currLocation.title }}</span>
                <small class="is-size-7 pl-1 has-text-weight-normal">
                  ({{ currLocation.coordinates }})
                </small>
              </button>
              <!-- </TransitionGroup> -->
            </div>

            <button
              v-if="locations.length > maxLocationsShowen"
              id="filter-location-btn"
              class="button"
              @click="showAll = !showAll"
            >
              <span>
                {{ showAll ? "Weniger anzeigen" : "Alle anzeigen" }}
              </span>
              <span class="icon">
                <img :src="showAll ? chevronUpSVG : chevronRightSVG" alt="" />
              </span>
            </button>
          </section>
          <section class="block">
            <h5 class="title is-size-5 mb-3">Neuer Ort</h5>
            <div class="is-flex mb-2">
              <div class="field">
                <label class="label">Buchstabe</label>
                <div class="control has-icons-left">
                  <div class="select">
                    <select v-model="selectionB">
                      <option
                        v-for="(option, index) in optionsB"
                        :value="option.value"
                        :key="'optionsB' + index"
                      >
                        {{ option.text }}
                      </option>
                    </select>
                  </div>
                  <div class="icon is-small is-left">
                    <img :src="arrowDownCircleSVG" alt="Buchstabe" />
                  </div>
                </div>
              </div>
              <div class="field ml-5">
                <label class="label">Zahl</label>
                <div class="control has-icons-left">
                  <div class="select">
                    <select v-model="selectionA">
                      <option
                        v-for="(option, index) in optionsA"
                        :value="option.value"
                        :key="'optionsA' + index"
                      >
                        {{ option.text }}
                      </option>
                    </select>
                  </div>
                  <div class="icon is-small is-left is-rotate-90">
                    <img :src="arrowDownCircleSVG" alt="Zahl" />
                  </div>
                </div>
              </div>
            </div>
            <div class="field">
              <p class="control">
                <a
                  class="button location-button"
                  @click="locationSelect(selectionB + selectionA)"
                >
                  Lets go!
                </a>
              </p>
            </div>
          </section>
        </div>
      </div>
    </div>
    <button
      class="modal-close is-large"
      aria-label="close"
      @click="$emit('close-modal')"
    ></button>
  </div>
</template>
