<script setup></script>
<template>
  <div class="card has-background-grey-darker game-setup">
    <!-- <div class="card-image"></div> -->
    <header class="card-header">
      <div class="card-header-title">
        <h4 class="title is-3 has-text-white">Wilkommen bei Life Goes On</h4>
      </div>
    </header>
    <div class="card-content has-text-white">
      <div class="block">
        Hier steht ein kurzer Einleitungstext. Life goes on ist ein tolles Spiel was
        sich mit einer tollen Geschichte beschäftigt. Lies die Anleitung aufmerksam
        durch und los gehts.
      </div>
      <div class="block">
        <h4 class="title is-4 has-text-white mb-3">Was du brauchst:</h4>
        <ol class="list has-dot">
          <li>Drucker</li>
          <li>Handys</li>
          <li>Tablet</li>
          <li>Mitspieler:innen</li>
        </ol>
      </div>
      <div class="block mb-5">
        <h4 class="title is-4 has-text-white mb-3">Anleitung</h4>
        <ol class="list">
          <li>
            Druck das PDF aus:
            <br />
            <button class="button is-warning ml-4 my-2">PDF runterladen</button>
          </li>
          <li>Leg alles bereit</li>
          <li>Usw.</li>
        </ol>
      </div>
    </div>
  </div>
</template>
