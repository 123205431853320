<script setup></script>

<template>
  <div class="modal is-active">
    <div class="modal-background has-background-black"></div>
    <div class="modal-content">
      <section class="hero">
        <div class="hero-body has-text-centered">
          <div class="loading-ring"></div>
          <p class="title is-1 has-text-warning">Loading</p>
          <p class="subtitle has-text-warning-light">Goes On</p>
        </div>
      </section>
    </div>
  </div>
</template>
