<script setup>
defineProps({ team: { type: Object, required: true } });
const emit = defineEmits(["on-go-back", "on-next"]);

function goBack() {
  emit("on-go-back");
}
function goNext() {
  emit("on-next");
}
</script>
<template>
  <div class="card has-background-grey-darker">
    <header class="card-header">
      <div class="card-header-title">
        <div>
          <h3 class="title is-3 has-text-white">Hier ist dein Code</h3>
          <h5 class="subtitle is-5 has-text-danger">
            Unbedingt aufschreiben oder richtig gut merken!
          </h5>
        </div>
      </div>
    </header>
    <div class="card-content">
      <h3 class="title is-3 has-text-white">{{ team.id }}</h3>
    </div>
    <footer class="card-footer">
      <div class="card-footer-item">
        <button class="button is-warning is-light" @click="goBack()">
          Zurück zur Anleitung
        </button>
      </div>
      <div class="card-footer-item">
        <button class="button is-warning" @click="goNext()">Spiel starten</button>
      </div>
    </footer>
  </div>
</template>
