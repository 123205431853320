import { Howl, Howler } from "howler";
import SystemSounds from "@/assets/audio/system_audio";

export class AudioPlayer {
    constructor() {
        // in playlist we store every file path and created howl instances 
        // [ {name:"optional", file:"path%to%file.mp3", howl:null} ]
        this.tracklist = [];

        // [{index,callback}]
        this.events = {
            onend: [],
            onfade: [],
            onload: [],
            onloaderror: [],
            onplayerror: [],
            onpause: [],
            onplay: [],
            onstop: [],
            onmute: [],
            onvolume: [],
            onrate: [],
            onseek: [],
            onunlock: [],
            onresume: [],
        };
        this.logLevel = "none";

        this.load(SystemSounds)
    }

    /**
    * Attempts to play an audio file.
    * @param  {String}  selector                Path to file or file name or index.
    * @param  {Boolean} retryAfterPlayError     Set callbacks to start a new playback attempt after ux error.
    * @param  {Number}  timeShouldHavePlayed    UNIX-Time of initial play call (should be part of play message from backend).
    * @return {Number}                          Index of track in playlist.
    */
    play(selector, timestamp, retryAfterPlayError = true) {
        let index = this.getTrackIndex(selector);
        if (index < 0)
            index = this.addTrack(selector);
        const track = this.tracklist[index];
        track.timestamp = timestamp || Date.now();


        // if we did not already load this track
        if (!track.howl)
            track.howl = this.initHowl(index, track.file)

        this.log("start playback ", track, " with index ", index);
        // if this track is already playing howler will start a second one in same howl object
        if (!track.howl.playing()) {
            timestamp ? this.playRelativeToTimestamp(track) : track.howl.play();
        }

        if (retryAfterPlayError) {
            // if this track can't be played right away, wait till it is unlocked and then reattemped play
            track.howl.once('playerror', () => {
                track.howl.once("unlock", () => {
                    this.playRelativeToTimestamp(track);
                })
            })
        }
        return index;
    }

    playRelativeToTimestamp(track) {
        let secs = (Date.now() - track.timestamp) / 1000;
        this.log("try playing relative to timestamp. jump to", secs);
        track.howl.seek(secs); // if seek is > duration howl stops playback
        track.howl.play();
    }

    /**
    * Add file to playlist (if not already there) and init howler for file.
    * @param  {Array | String} files     File(s) to load. [{file:"...mp3", name:"track1"}]
    */
    load(files) {
        if (typeof files === "string" || files instanceof String)
            files = [{ file: files, name: files }];
        for (let i = 0; i < files.length; i++) {
            const item = files[i];
            let index = this.getTrackIndex(item.file);
            if (index < 0)
                index = this.addTrack(item);
            this.tracklist[index].howl = this.initHowl(index, item.file)
        }
    }

    stop(file) {
        if (typeof file !== 'undefined') {
            const index = this.getTrackIndex(file);
            if (index < 0)
                return
            this.tracklist[index].howl.stop();
        } else {
            Howler.stop();
        }
    }

    initHowl(index, file) {
        const howlInstance =
            new Howl({
                src: [file],
                autoplay: false,
                html5: true, // Force to HTML5 so that the audio can stream in (best for large files).
                onload: () => this.emit("onload", index),
                onloaderror: (sound, error) => this.emit("onloaderror", index, error),
                onplayerror: (sound, error) => this.emit("onplayerror", index, error),
                onunlock: () => this.emit("onunlock", index),
                onplay: () =>
                    this.emit("onplay", index),
                onend: () => this.emit("onend", index),
                onpause: () => this.emit("onpause", index),
                onstop: () => this.emit("onstop", index),
                onseek: () => this.emit("onseek", index),
            });
        return howlInstance;
    }

    /**
    * Seek to a new position in the currently playing track.
    * @param  {Number} secs     Seconds to skip to.
    */
    skipTo(secs) {
        if (this.sound.playing())
            this.sound.seek(secs);
    }

    /**
    * Returns current time in audio clip or 0 if no track is playing.
    */
    currentTime() {
        return this.sound.seek();
    }

    /**
    * Adds track to playlist.
    * @param  {Object} track     {file:"...mp3", name:"track1"}.
    * @return {Number}          Index of track in playlist.
    */
    addTrack(track) {
        const length = this.tracklist.push({ file: track.file, name: track?.name || track.file, howl: null });
        this.log("added track to playlist", track?.name || track.file);
        return length - 1;
    }

    /**
    * Finds the index of track. If necessary adds new track to playlist.
    * @param  {Number | String} selector    Index or filename.
    * @return {Number}                      Index of track in playlist or -1 if no track found.
    */
    getTrackIndex(selector) {
        if (typeof selector === 'number')
            return this.tracklist.length > selector ? selector : -1
        else
            return this.tracklist.findIndex(t => t.file === selector || t.name === selector);
    }

    /**
    * Format the time from seconds to M:SS.
    * @param  {Number} secs     Seconds to format.
    * @return {String}          Formatted time.
    */
    formatTime(secs) {
        var minutes = Math.floor(secs / 60) || 0;
        var seconds = (secs - minutes * 60) || 0;

        return minutes + ':' + (seconds < 10 ? '0' : '') + seconds;
    }

    on(event, callback) {
        if (!event.startsWith('on'))
            event = 'on' + event;
        const events = this.events[event];
        if (events && typeof callback === 'function')
            events.push(callback);
    }

    emit(event, trackIndex, ...payload) {
        this.log("emit", event, trackIndex, ...payload);
        if (Object.prototype.hasOwnProperty.call(this.events, event)) {
            const events = this.events[event];
            for (let i = events.length - 1; i >= 0; i--) {
                const fn = events[i];
                fn.call(this, trackIndex, ...payload);
            }
        }
    }

    log(...args) {
        if (this.logLevel === "debug") {
            let style = [
                "color: #fff",
                "background-color: #444",
                "padding: 2px 4px",
                "border-radius: 2px"
            ].join(";");
            console.log(`%cPlayer Log:`, style, ...args);
        }
    }
}