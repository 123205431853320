<script setup>
import { ref, watch } from "vue";
import LOADINGANIMATION from "@/assets/image/zentrale-loading.gif";
const props = defineProps({ location: { type: Object, required: true } });

const bgImageRef = ref(undefined);
const transitionImageRef = ref(undefined);
const isTransition = ref(false);
const animationTime = 2000;
const imageTime = 1200;

function load(src) {
  return new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener("load", resolve);
    image.addEventListener("error", reject);
    image.src = src;
  });
}

watch(
  () => props.location,
  (newLocation, oldLocation) => {
    if (newLocation?.map_tile !== oldLocation?.map_tile) {
      const transitionStartTime = Date.now();
      const image = newLocation.map_tile;
      isTransition.value = true;
      transitionImageRef.value.style.backgroundImage = `url(${LOADINGANIMATION})`;
      load(image)
        .then(() => {
          const now = Date.now();
          const diffTime = now - transitionStartTime;
          const remainingTime = animationTime - diffTime;
          setTimeout(
            () => {
              transitionImageRef.value.style.backgroundImage = `url(${image})`;
              bgImageRef.value.style.backgroundImage = `url(${image})`;
              setTimeout(() => (isTransition.value = false), imageTime);
            },
            remainingTime > 0 ? remainingTime : 0
          );
        })
        .catch(() => {
          console.error("image load failed");
          isTransition.value = false;
        });
    }
  }
);
</script>
<template>
  <div class="bg-image-container" ref="bgImageRef"></div>
  <Transition name="fade-out">
    <div
      v-show="isTransition"
      class="bg-image-container loading"
      ref="transitionImageRef"
    ></div>
  </Transition>
</template>
