<script setup>
import { inject, ref, computed, watch } from "vue";
import { VueScrollPicker } from "vue-scroll-picker";

defineExpose({ getDate });

const socket = inject("socket");
const audioPlayer = inject("audioPlayer");
let isFirst = true;
let invalidateSelectionOnDialTurn = true;

const currentYear = ref(2024);
const currentMonth = ref(1);
const currentDay = ref(1);

const years = computed(() => {
  const currYear = 2024;
  const lastYear = 1965;
  return Array.from(
    { length: currYear - lastYear + 1 },
    (_, index) => lastYear + index
  ).reverse();
});

const months = ref(Array.from({ length: 12 }, (_, index) => pad(index + 1)));

const days = computed(() => {
  const lastDay = new Date(currentYear.value, currentMonth.value, 0).getDate();
  return Array.from({ length: lastDay }, (_, index) => pad(index + 1));
});

function getDate() {
  invalidateSelectionOnDialTurn = true;
  return `${currentDay.value}.${currentMonth.value}.${currentYear.value}`;
}

function pad(num) {
  return ("0" + num).slice(-2);
}
watch([currentYear, currentMonth, currentDay], () => {
  if (isFirst) {
    isFirst = false;
    return;
  }
  audioPlayer.play("DIAL");
  if (invalidateSelectionOnDialTurn) {
    socket.emit("zeisig", { dial: "CHANGE" });
    invalidateSelectionOnDialTurn = false;
  }
});
</script>

<template>
  <div class="is-flex">
    <VueScrollPicker
      :touchSensitivity="2"
      :dragSensitivity="3"
      :options="days"
      v-model="currentDay"
    />
    <span class="is-align-self-center">.</span>
    <VueScrollPicker :options="months" v-model="currentMonth" />
    <span class="is-align-self-center">.</span>
    <VueScrollPicker
      :touchSensitivity="2"
      :dragSensitivity="3"
      :options="years"
      v-model="currentYear"
    />
  </div>
</template>
