<script setup>
import trashSVG from "@/assets/svg/trash.svg";
defineProps({
  TEAM: { type: String, required: true },
  PLAYER: { type: Array, required: true },
});
const emit = defineEmits(["on-next", "on-delete-player"]);

function deletePlayer(player) {
  emit("on-delete-player", player);
}
</script>
<template>
  <div class="card has-background-grey-darker">
    <header class="card-header">
      <div class="card-header-title">
        <div>
          <h3 class="title is-3 has-text-white">Mitspieler:innen</h3>
        </div>
      </div>
    </header>
    <div class="card-content has-text-white">
      <p class="pb-2">
        Gebt den folgenden Code auf den Mobilgeräten ein um dem Spiel beizutreten:
      </p>
      <h3 class="title is-5 has-text-white">{{ TEAM }}</h3>
      <!-- <div class="block">
        <span>Bitte öffne die folgende URL auf deinem Smartphone:</span>
        <h3 class="title is-4 has-text-warning">lifegoeson.machinaex.org/join</h3>
      </div> -->
      <!-- <div class="block">
        <p>Und gib den Code ein:</p>
        <h3 class="title is-3 has-text-white">{{ TEAM }}</h3>
      </div> -->
      <div class="block">
        <h5 class="title is-5 has-text-white mb-3">Verbundene Spieler:innen</h5>
        <div v-if="PLAYER.length > 0" class="player-list">
          <TransitionGroup name="list" tag="ul">
            <li v-for="p in PLAYER" :key="TEAM + 'pl' + p.name">
              <span class="is-impact-label is-size-5 mr-3">
                {{ p.name }}
              </span>
              <span
                class="icon has-background-danger is-pulled-right"
                @click="deletePlayer(p.name)"
              >
                <img :src="trashSVG" alt="Löschen" />
              </span>
            </li>
          </TransitionGroup>
        </div>
      </div>
    </div>
  </div>
</template>
