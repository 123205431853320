<script setup>
import { ref, inject } from "vue";
import { hasKey } from "@/utils";
import arrowSVG from "@/assets/svg/arrow-right-line.svg";
import MarkdownRenderer from "@/components/MarkdownRenderer";
import StoryPrompt from "@/components/StoryPrompt.vue";

defineExpose({ reset });

const storyQueue = ref([]);
const currentStoryMessage = ref(undefined);
const currentStoryMessageIndex = ref(0);
let currentStoryMessageDone = false;
const currentStoryPrompt = ref(undefined);

const socket = inject("socket");
socket.on("storyMessage", (data) => addStoryItem(data, "storyMessage"));
socket.on("storyPrompt", (data) => addStoryItem(data, "storyPrompt"));
socket.on("status", (status) => {
  if (hasKey(status, "storyMessage"))
    addStoryItem(status.storyMessage, "storyMessage");
  if (hasKey(status, "storyPrompt")) {
    if (Object.keys(status.storyPrompt).length > 0)
      addStoryItem(status.storyPrompt, "storyPrompt");
  }
});

socket.on("control", (data) => {
  if (hasKey(data, "payload.story")) {
    switch (data.payload.story) {
      case "RESET":
        reset();
        break;
    }
  }
});

function addStoryItem(data, type) {
  // bugfix for old object structure in respond. will be fixed
  if (Array.isArray(data)) {
    data = {
      timestamp: Date.now(),
      id: "old_format_" + Date.now(),
      payload: data,
    };
  }
  //TODO: if message is not already in storyQueue add it
  const storyItem = Object.assign({ type }, data);
  storyQueue.value.push(storyItem);
  if (type == "storyMessage") {
    currentStoryMessageIndex.value = 0;
    currentStoryMessage.value = storyItem;
    currentStoryPrompt.value = undefined;
    checkStoryMessageDone();
  } else if (type == "storyPrompt" && currentStoryMessageDone) {
    currentStoryPrompt.value = storyItem;
  }
}

function getNextStoryItem(currentId) {
  let index = storyQueue.value.findIndex((item) => item.id === currentId);
  if (index == -1 || index >= storyQueue.value.length - 1) return undefined;
  else return storyQueue.value[index + 1];
}

function selectedPrompt(evt) {
  if (evt.type == "option") {
    socket.emit("storyInput", { type: "option", option: evt.userinput });
  }
  if (evt.type == "text") {
    socket.emit("storyInput", { type: "text", text: evt.userinput });
  }
  currentStoryPrompt.value = undefined;
}

function turnPage(direction) {
  currentStoryMessageIndex.value += direction;
  checkStoryMessageDone();
}

function checkStoryMessageDone() {
  if (currentStoryMessageIndex.value >= currentStoryMessage.value.payload.length - 1)
    doneReading();
  else currentStoryMessageDone = false;
}

function doneReading() {
  currentStoryMessageDone = true;
  const nextStoryItem = getNextStoryItem(currentStoryMessage.value.id);
  if (nextStoryItem == undefined) {
    console.log("no next message");
  } else if (nextStoryItem.type == "storyPrompt") {
    currentStoryPrompt.value = nextStoryItem;
  } else if (nextStoryItem.type == "storyMessage") {
    currentStoryMessageIndex.value = 0;
    currentStoryMessage.value = nextStoryItem;
    currentStoryMessageDone = false;
  } else {
    currentStoryMessage.value = undefined;
  }
}

function reset() {
  storyQueue.value = [];
  currentStoryMessage.value = undefined;
  currentStoryMessageIndex.value = 0;
  currentStoryMessageDone = false;
  currentStoryPrompt.value = undefined;
}
</script>

<template>
  <div id="story-container">
    <div id="story-paper">
      <div id="paper-side">
        <div class="is-hole-punch"></div>
        <div class="is-hole-punch"></div>
      </div>
      <div id="paper-content">
        <markdown-renderer
          v-if="currentStoryMessage"
          :source="currentStoryMessage.payload[currentStoryMessageIndex].text"
        />
        <!-- <hr v-if="currentStoryPrompt" /> -->
        <StoryPrompt
          v-if="
            currentStoryPrompt &&
            currentStoryMessageIndex >= currentStoryMessage.payload.length - 1
          "
          :prompt="currentStoryPrompt.payload"
          @prompt-selection="selectedPrompt"
        />
      </div>
    </div>
    <div v-if="currentStoryMessage?.payload" class="story-navigation">
      <button
        class="button px-2"
        :class="{ 'is-invisible': currentStoryMessageIndex < 1 }"
        @click="turnPage(-1)"
      >
        <img class="image is-24x24 is-rotate" :src="arrowSVG" alt="Zurück" />
      </button>
      <button
        class="button px-2"
        :class="{
          'is-invisible':
            currentStoryMessageIndex >= currentStoryMessage.payload.length - 1,
        }"
        @click="turnPage(1)"
      >
        <img class="image is-24x24" :src="arrowSVG" alt="Weiter" />
      </button>
    </div>
  </div>
</template>
