<script setup>
import { ref, computed, provide, inject } from "vue";
import { hasKey, getLocalValueOrDefault } from "@/utils";
import io from "socket.io-client";
import { AudioPlayer } from "@/utils/player";
import LocationContainer from "@/components/LocationContainer.vue";
import StoryContainer from "@/components/StoryContainer.vue";
import StatusBar from "@/components/StatusBar.vue";
import ZeisigContainer from "@/components/ZeisigContainer.vue";
import SettingsOverlay from "@/components/SettingsOverlay.vue";
import LoginOverlay from "@/components/LoginOverlay.vue";
import SplashScreen from "@/components/SplashScreen.vue";

const defaultConfig = inject("globalAppConfig");
const { DEVICE, GAME, BASE_URL } = defaultConfig;

const socket = io(`${BASE_URL}/game/${GAME}/livingroom/${DEVICE}`);
provide("socket", socket);

const audioPlayer = new AudioPlayer();
provide("audioPlayer", audioPlayer);

const settingsOpen = ref(false);

const CONNECTED = ref(false); // socket connection established
const TEAM = ref(getLocalValueOrDefault("TEAM", defaultConfig?.TEAM || ""));
const LOGIN = ref(getLocalValueOrDefault("LOGINSTEP", "START"));
const OLDTEAM = ref("");
const JOINED = computed(() => (TEAM.value ? true : false)); // successfully joined a team session
const PLAYER = ref([]);

socket.on("connect", () => {
  console.log("connected to game socket");
  CONNECTED.value = socket.connected;
  if (TEAM.value !== "") {
    socket.emit("joinTeam", { team: TEAM.value });
  }
});

socket.on("connect_error", (err) => {
  CONNECTED.value = socket.connected;
  console.log(`connect_error due to ${err.message}`);
});

socket.on("disconnect", (reason) => {
  CONNECTED.value = socket.connected;
  console.log(`disconnect due to ${reason}`);
  // the disconnection was initiated by the server, you need to reconnect manually
  if (reason === "io server disconnect") {
    resetTeam();
    socket.connect();
  }
  // else if(reason === "ping timeout"){} // reconnect happens automatic
});

socket.on("error", (error) => {
  if (hasKey(error, "name")) {
    console.error("Socket error message", error.name, error?.message);
    if (error.name === "NotFoundError") {
      resetTeam();
    }
  }
});

socket.on("status", (data) => {
  console.log(`Socket status:`, data);
  if (hasKey(data, "connectionStatus")) {
    PLAYER.value = data.connectionStatus.payload;
  }
});

socket.on("connectionStatus", (data) => {
  console.log(`Socket connectionStatus:`, data);
  if (hasKey(data, "payload")) {
    PLAYER.value = data.payload;
  }
});

socket.onAny((event, ...args) => {
  const knownEvents = ["status", "storyMessage", "storyPrompt", "control"];
  if (!knownEvents.includes(event)) {
    console.log(`Socket onAny: ${event}`, ...args);
  }
  // FIXME: sometimes on game setup the event is not firing but onAny does
  if (event === "connectionStatus") {
    if (hasKey(args[0], "payload")) {
      PLAYER.value = args[0].payload;
    }
  }
});

function resetTeam() {
  OLDTEAM.value = TEAM.value;
  TEAM.value = "";
  localStorage.removeItem("TEAM");
  LOGIN.value = "START";
  localStorage.removeItem("LOGINSTEP");
  PLAYER.value = [];
  socket.removeAllListeners();
}

function setTeamData(team) {
  TEAM.value = team.id;
  localStorage.setItem("TEAM", TEAM.value);
  LOGIN.value = "DONE";
  localStorage.setItem("LOGINSTEP", LOGIN.value);
}

function triggerSettings() {
  settingsOpen.value = !settingsOpen.value;
}
</script>

<template>
  <main class="grid-container">
    <LocationContainer />
    <StoryContainer />
    <ZeisigContainer :PLAYER="PLAYER" />
  </main>
  <StatusBar v-bind="{ CONNECTED, JOINED, TEAM }" @open-settings="triggerSettings" />
  <!-- OVERLAYS -->
  <SettingsOverlay
    v-if="settingsOpen"
    v-bind="{ BASE_URL, GAME, TEAM, PLAYER }"
    @change-team="setTeamData"
    @logout="resetTeam"
    @close="triggerSettings"
  />
  <LoginOverlay
    v-if="!TEAM || LOGIN !== 'DONE'"
    v-bind="{ BASE_URL, GAME, TEAM, PLAYER }"
    @logged-in="setTeamData"
  />
  <SplashScreen v-if="!CONNECTED" />
</template>
