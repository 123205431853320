import { createApp } from 'vue'
import App from '@/App.vue'
import "@/styles/main.scss";

async function init() {
    const response = await fetch("./config.json");
    if (response.status !== 200)
        throw new Error(`Could not load config file from ${response.url}`);
    const config = await response.json();
    console.log(config);

    const app = createApp(App);
    // TODO: fetch caches from localStorage;
    // console.log(localStorage.storyLog)
    app.provide('globalAppConfig', config)

    app
        .mount('#app')
}

init()

