<script setup>
import { ref, inject } from "vue";
import { hasKey } from "@/utils";
import DateDial from "@/components/DateDial.vue";
import LOGO from "@/assets/svg/zeisig_logo.svg";

defineProps({ PLAYER: { type: Array, required: true } });

const socket = inject("socket");
const audioPlayer = inject("audioPlayer");

const terminal = ref([]);
const lcdButtons = ref({ A: "-", B: "-", C: "-" });
const refDateDial = ref(undefined);

const locked = ref(false);
const display = ref("HIDE");

function trigger() {
  if (!locked.value) {
    display.value === "SHOW" ? close() : open();
  }
}

function open() {
  if (display.value !== "SHOW") {
    display.value = "SHOW";
    audioPlayer.play("SLIDE_IN");
  }
}

function close() {
  if (display.value !== "CLOSE") {
    display.value = "CLOSE";
    audioPlayer.play("SLIDE_OUT");
  }
}

function lcdButtonClick(btnId) {
  audioPlayer.play("BUTTON_LCD");
  socket.emit("zeisig", { button: { id: btnId, label: lcdButtons.value[btnId] } });
}

function updateDate() {
  const date = refDateDial.value.getDate();
  socket.emit("zeisig", { date });
}

socket.on("status", (status) => {
  if (hasKey(status, "control.buttons.A"))
    lcdButtons.value.A = status.control.buttons.A;
  if (hasKey(status, "control.buttons.B"))
    lcdButtons.value.B = status.control.buttons.B;
  if (hasKey(status, "control.buttons.C"))
    lcdButtons.value.C = status.control.buttons.C;
  if (hasKey(status, "control.zeisig")) {
    switch (status.control.zeisig) {
      case "LOCK":
        locked.value = true;
        display.value = display.value === "HIDE" ? "CLOSE" : display.value;
        break;
      case "UNLOCK":
        locked.value = false;
        display.value = display.value === "HIDE" ? "CLOSE" : display.value;
        break;
      case "SHOW":
        open();
        break;
      case "CLOSE":
        close();
        break;
      case "HIDE":
        display.value = "HIDE";
        break;
      case "HINT":
        break;
    }
  }
  if (hasKey(status, "control.terminal")) {
    let terminalText = status.control.terminal;
    if (typeof terminalText === "string" || terminalText instanceof String) {
      terminalText = [terminalText];
    }
    terminal.value = terminalText;

    const text = terminal.value.join("");
    if (text.includes("Keine Zeitdatei gefunden")) audioPlayer.play("NOTFOUND");
    else audioPlayer.play("NEW_TEXT");
  }
});

socket.on("control", (data) => {
  if (hasKey(data, "payload.buttons.A")) lcdButtons.value.A = data.payload.buttons.A;
  if (hasKey(data, "payload.buttons.B")) lcdButtons.value.B = data.payload.buttons.B;
  if (hasKey(data, "payload.buttons.C")) lcdButtons.value.C = data.payload.buttons.C;
  if (hasKey(data, "payload.zeisig")) {
    switch (data.payload.zeisig) {
      case "LOCK":
        locked.value = true;
        display.value = display.value === "HIDE" ? "CLOSE" : display.value;
        break;
      case "UNLOCK":
        locked.value = false;
        display.value = display.value === "HIDE" ? "CLOSE" : display.value;
        break;
      case "SHOW":
        open();
        break;
      case "CLOSE":
        close();
        break;
      case "HIDE":
        display.value = "HIDE";
        break;
      case "HINT":
        break;
    }
  }
  if (hasKey(data, "payload.terminal")) {
    let terminalText = data.payload.terminal;
    if (typeof terminalText === "string" || terminalText instanceof String) {
      terminalText = [terminalText];
    }
    terminal.value = terminalText;
    const text = terminal.value.join("");
    if (display.value === "SHOW") {
      if (text.includes("Suche Zeitdatei")) audioPlayer.play("SEARCH");
      else if (text.includes("Keine Zeitdatei gefunden"))
        audioPlayer.play("NOTFOUND");
      else if (text.includes("Aus Datenschutzrechtlichen Gründen"))
        audioPlayer.play("DATENSCHUTZ");
      else audioPlayer.play("NEWTEXT");
    }
  }
});

socket.on("storyMessage", () => {
  if (display.value == "SHOW") {
    close();
  }
});

socket.on("storyPrompt", () => {
  if (display.value == "SHOW") {
    close();
  }
});
</script>

<template>
  <Transition name="fade">
    <div v-if="display == 'SHOW'" class="is-overlay" @click="close"></div>
  </Transition>

  <div
    id="zeisig-container"
    :class="{
      'slide-in': display === 'SHOW',
      'is-outside': display === 'HIDE',
      'is-locked': locked,
    }"
  >
    <div id="zeisig-trigger" @click="trigger">
      <img :src="LOGO" alt="ZEISIG" />
    </div>
    <div id="zeisig-device">
      <section class="level date-container">
        <p class="level-item date-item">
          <DateDial ref="refDateDial" />
        </p>
        <p class="level-item date-item">
          <button class="button zeisig-button" @click="updateDate">scan</button>
        </p>
      </section>
      <section class="is-flex-grow-1 pb-3">
        <ul class="player-container">
          <li v-for="p in PLAYER" :key="'pl' + p.name" class="player-item">
            <div
              :class="[
                'mr-2',
                p.connections > 0
                  ? 'is-indicator-connected'
                  : 'is-indicator-warning',
              ]"
            ></div>
            <span class="is-impact-label is-size-4">{{ p.name }}</span>
          </li>
        </ul>
      </section>
      <section class="is-flex display-area">
        <div id="terminal">
          <p v-for="(text, index) in terminal" :key="index" class="row">
            {{ text }}
          </p>
        </div>
        <div
          class="is-flex is-flex-direction-column is-justify-content-space-between"
        >
          <button
            class="button p-0 zeisig-display-button"
            @click="lcdButtonClick('A')"
          >
            {{ lcdButtons.A }}
          </button>
          <button
            class="button p-0 zeisig-display-button"
            @click="lcdButtonClick('B')"
          >
            {{ lcdButtons.B }}
          </button>
          <button
            class="button p-0 zeisig-display-button"
            @click="lcdButtonClick('C')"
          >
            {{ lcdButtons.C }}
          </button>
        </div>
      </section>
    </div>
  </div>
</template>
