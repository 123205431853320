<script setup>
import { ref } from "vue";

defineProps({
  prompt: { type: Array, required: true },
});

const emit = defineEmits(["prompt-selection"]);

const userinput = ref(null);

function promptSelection(e, action, indexChosen) {
  if (action.type == "text") {
    action.userinput = userinput.value;
  }
  if (action.type == "option") {
    action.userinput = indexChosen;
  }
  emit("prompt-selection", action);
}
</script>

<template>
  <div class="is-desktop">
    <div v-for="(action, index) in prompt" :key="action" class="prompt-container">
      <!-- OPTIONS BUTTON -->
      <a
        v-if="action.type == 'option'"
        :class="[`is-${action.style}`]"
        @click="promptSelection(e, action, index)"
      >
        <span>
          {{ action.label }}
        </span>
      </a>
      <!-- OR INPUT TEXT -->
      <div v-if="action.type == 'text'" class="field has-addons">
        <div class="control is-expanded">
          <input
            class="input"
            type="text"
            placeholder="Hier eingeben"
            :value="userinput"
            @input="
              (evt) => {
                userinput = evt.target.value;
              }
            "
            @keyup.enter="promptSelection(e, action)"
          />
        </div>
        <div class="control">
          <a
            :class="['button', `is-${action.style}`]"
            @click="promptSelection(e, action)"
          >
            {{ action.label }}
          </a>
        </div>
      </div>
    </div>
  </div>
</template>
