<script setup>
import { ref, inject } from "vue";
import { hasKey, getRandomInt } from "@/utils";
import LocationOverlay from "@/components/LocationOverlay.vue";
import LocationBackground from "@/components/LocationBackground.vue";
import { computed } from "@vue/reactivity";

const location = ref({});
const positionObject = ref({});
const position = computed(() => {
  if (
    hasKey(positionObject.value, location.value.name) &&
    hasKey(location.value, "positions")
  ) {
    let position = positionObject.value[location.value.name];
    return location.value.positions[position];
  }
});
const locations = ref([]);
const showLocationModal = ref(false);
const locked = ref(false);
const display = ref("HIDE");

const socket = inject("socket");
const audioPlayer = inject("audioPlayer");

function updateLocation(location) {
  showLocationModal.value = false;
  socket.emit("location", location);
  socket.emit("zeisig", { dial: "CHANGE" }); // invalidate zeisig date selection on location change
}

socket.on("status", (status) => {
  if (hasKey(status, "visited")) {
    locations.value = status.visited;
  }
  if (hasKey(status, "control.location")) {
    const currID = status.control.location._id;
    const index = locations.value.findIndex((l) => l._id === currID);
    if (index < 0) locations.value.push(status.control.location);
    // sort known locations by last visited
    if (status.control.location?.visited) {
      const ordered = status.control.location?.visited;
      locations.value.sort((a, b) => {
        const aIndex = ordered.findIndex((name) => name === a.name);
        const bIndex = ordered.findIndex((name) => name === b.name);
        return aIndex < bIndex ? 1 : -1;
      });
    }
    location.value = status.control.location;
  }
  if (hasKey(status, "control.position")) {
    positionObject.value = status.control.position;
  }
  if (hasKey(status, "control.map")) {
    switch (status.control.map) {
      case "LOCK":
        locked.value = true;
        display.value = display.value === "HIDE" ? "SHOW" : display.value;
        break;
      case "UNLOCK":
        locked.value = false;
        display.value = display.value === "HIDE" ? "SHOW" : display.value;
        break;
      case "SHOW":
        display.value = "SHOW";
        break;
      case "CLOSE":
        display.value = "CLOSE";
        break;
      case "HIDE":
        display.value = "HIDE";
        break;
      case "HINT":
        break;
    }
  }
});

socket.on("control", (data) => {
  if (hasKey(data, "payload.location")) {
    positionObject.value = {};
    const currID = data.payload.location._id;
    const index = locations.value.findIndex((l) => l._id === currID);
    if (index < 0) locations.value.push(data.payload.location);
    // sort known locations by last visited
    if (data.payload.location?.visited) {
      const ordered = data.payload.location.visited;
      locations.value.sort((a, b) => {
        const aIndex = ordered.findIndex((name) => name === a.name);
        const bIndex = ordered.findIndex((name) => name === b.name);
        return aIndex < bIndex ? 1 : -1;
      });
    }
    location.value = data.payload.location;
    const locationSound = `Ortswechsel_${getRandomInt(1, 7)}`;
    audioPlayer.play(locationSound);
  }
  if (hasKey(data, "position")) {
    positionObject.value = data.position;
  }
  if (hasKey(data, "payload.map")) {
    switch (data.payload.map) {
      case "LOCK":
        locked.value = true;
        display.value = display.value === "HIDE" ? "SHOW" : display.value;
        break;
      case "UNLOCK":
        locked.value = false;
        display.value = display.value === "HIDE" ? "SHOW" : display.value;
        break;
      case "SHOW":
        display.value = "SHOW";
        break;
      case "CLOSE":
        display.value = "CLOSE";
        break;
      case "HIDE":
        display.value = "HIDE";
        break;
      case "HINT":
        break;
    }
  }
});
</script>

<template>
  <LocationBackground v-bind="{ location }" />

  <div v-show="display === 'SHOW'" id="location-card-container">
    <div id="location-card">
      <div class="location-header">
        <img
          class="mb-3 px-3 location-heading"
          src="@/assets/image/stadtplan.png"
          alt="stadtplan"
        />
        <button
          :disabled="locked"
          class="button location-change-button"
          @click="showLocationModal = true"
        >
          Ort wechseln
        </button>
      </div>
      <div class="location-body">
        <h5 class="is-size-5 has-text-weight-bold has-text-centered">
          {{ location?.title || "" }}
          <br />
          <small v-if="position?.title" class="has-text-weight-medium mt-1">
            {{ position.title }}
          </small>
        </h5>
        <!-- <p class="has-text-centered">
          <span>Kühlhaus</span>
          &
          <span>Büro mit Fenstern</span>
        </p> -->
      </div>
    </div>
  </div>

  <LocationOverlay
    v-if="showLocationModal"
    v-bind="{ location, locations }"
    @close-modal="showLocationModal = false"
    @location-select="updateLocation"
  />
</template>
